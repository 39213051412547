<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio cliente</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="dark"></ion-spinner>
      </div>

      <div v-else class="customer_content">
        <div class="customer_actions">
          <div
            :href="'tel:+39' + cliente.customers_phone"
            class="ion-padding-horizontal ion-text-capitalize"
          >
            Chiama
          </div>
          <div
            :href="`mailto:${cliente.customers_email}`"
            class="ion-padding-horizontal ion-text-capitalize"
          >
            Email
          </div>
          <div
            :href="`https://maps.google.com/?q=${cliente.customers_address} ${cliente.customers_city}`"
            target="_blank"
            class="ion-padding-horizontal ion-text-capitalize"
          >
            Naviga
          </div>
        </div>

        <div class="customer_informations">
          <div class="item">
            <div class="label">Cliente</div>
            <div class="field">
              {{ riferimentoCliente(cliente) }}
            </div>
          </div>

          <div class="item">
            <div class="label">Città</div>
            <div class="field">
              {{ cliente.customers_city }}
            </div>
          </div>

          <div class="item">
            <div class="label">Indirizzo</div>
            <div class="field">
              {{ cliente.customers_address }}
            </div>
          </div>

          <div class="item">
            <div class="label">Email</div>
            <div class="field">
              {{ cliente.customers_email || "-" }}
            </div>
          </div>

          <div class="item">
            <div class="label">Recapito telefonico</div>
            <div class="field">
              {{
                cliente.customers_phone ? `+39 ${cliente.customers_phone}` : "-"
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="impianti_associati_container">
        <div class="title">Impianti associati</div>
        <div v-if="impianti.length != 0">
          <div
            v-for="impianto in impianti"
            :key="impianto.projects_id"
            class="impianto ion-padding-top"
            @click="openCommessaDetail(impianto.projects_id)"
          >
            <div class="impianto_info">
              <div class="title">
                {{ impianto.projects_name }}
              </div>
              <div class="tipologia">
                {{ checkField(impianto.projects_category_value) }}
              </div>
            </div>
            <div class="impianto_detail">
              <ion-icon
                :icon="chevronForward"
                color="dark"
                class="custom_chevron"
              >
              </ion-icon>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="impianto">
            <div class="no_impianti">
              <ion-text color="danger">Nessun impianto associato</ion-text>
            </div>
          </div>
        </div>
      </div>

      <!--       <ion-card style="margin-top: 50px">
        <ion-card-header class="bg-primary">
          <ion-card-title>
            <ion-text color="light">
              <p class="card_title">Elenco impianti</p>
            </ion-text>
          </ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <div v-if="loadingCommesse" class="loading_spinner_commesse">
            <ion-spinner name="crescent" color="primary"></ion-spinner>
          </div>
          <div v-else>
            <div
              v-if="impianti.length == 0"
              class="ion-text-center ion-margin-top"
            >
              <ion-text color="danger">
                Il cliente selezionato non ha impianti associati
              </ion-text>
            </div>
            <div v-else>
              <div
                v-for="impianto in impianti"
                :key="impianto.projects_id"
                class="impianto ion-padding-top"
                @click="openCommessaDetail(impianto.projects_id)"
              >
                <div class="impianto_header">
                  <ion-text color="primary">
                    <h2>
                      <strong>{{ impianto.projects_name }} </strong>
                    </h2>
                  </ion-text>
                </div>
                <ion-list>
                  <ion-item class="ion-no-padding">
                    <ion-label color="primary"> Tipologia </ion-label>
                    <ion-text>
                      <p class="base_text">
                        {{ checkField(impianto.projects_category_value) }}
                      </p>
                    </ion-text>
                  </ion-item>
                  <ion-item class="ion-no-padding">
                    <ion-label color="primary">Stato</ion-label>
                    <ion-text>
                      <p class="base_text" v-if="impianto.projects_status_id">
                        <ion-badge
                          :color="statoImpianto(impianto.projects_status_id)"
                        >
                          {{ impianto.projects_status_value }}
                        </ion-badge>
                      </p>
                      <p class="base_text" v-else>-</p>
                    </ion-text>
                  </ion-item>
                </ion-list>
              </div>
            </div>
          </div>
        </ion-card-content>
      </ion-card> -->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  /* IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonBadge, */
  IonText,
  IonIcon,
  //IonButton,
} from "@ionic/vue";
import { add, chevronForward } from "ionicons/icons";

import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import apiCommesse from "../services/commesse";
import { openToast } from "../services/toast";
import { getCustomer } from "../services/localbase";

export default {
  name: "ClienteDetail",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonSpinner,
    /* IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonBadge,*/
    IonText,
    IonIcon,
    //IonButton,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id; //customers_id by route params
    const router = useRouter();
    const loading = ref(false);
    const loadingCommesse = ref(false);
    const cliente = ref([]);
    const impianti = ref([]);

    function riferimentoCliente(cliente) {
      if (cliente.customers_company) {
        return `${cliente.customers_company}`;
      } else if (cliente.customers_name && cliente.customers_last_name) {
        return `${cliente.customers_name} ${cliente.customers_last_name}`;
      } else if (cliente.customers_name && !cliente.customers_last_name) {
        return `${cliente.customers_name}`;
      } else if (!cliente.customers_name && !cliente.customers_last_name) {
        return `${cliente.customers_last_name}`;
      }
    }

    //Set correct background for impianto status
    const statoImpianto = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 2) {
          //da installare
          className = "danger";
        } else if (statusId == 3) {
          //lavori in corso
          className = "orange";
        } else if (statusId == 4) {
          //cancellato
          className = "dark";
        } else if (statusId == 5) {
          //completato
          className = "success";
        }
        return className;
      };
    });

    //Return '-' if a customer field is null or empty
    function checkField(field) {
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    //Passing id to retrive detail in ClienteDetal page
    function openCommessaDetail(idCommessa) {
      router.push(`${id}/commessa/${idCommessa}`);
    }

    async function getCliente(id) {
      loading.value = true;
      const customer = await getCustomer(id);
      return customer;
    }
    //Get customer data from indexedDB
    function getClienteDetail(id) {
      getCliente(id)
        .then((customer) => {
          //console.log(customer);
          cliente.value = customer;
        })
        .then(() => {
          loading.value = false;
        });
    }
    getClienteDetail(id);

    onMounted(() => {
      /* GET CUSTOMER COMMESSE */
      loadingCommesse.value = true;
      apiCommesse
        .getClienteCommesse(id)
        .then((response) => {
          //console.log(response);
          impianti.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta degli impianti", "danger");
        })
        .finally(() => {
          loadingCommesse.value = false;
        });
    });

    return {
      id,
      loading,
      loadingCommesse,
      riferimentoCliente,
      openCommessaDetail,
      cliente,
      impianti,
      statoImpianto,
      checkField,
      add,
      /* New field for figma layout */
      chevronForward,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_spinner_commesse {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.impianto {
  margin-bottom: 5px;
}
.impianto_codice {
  margin-bottom: 0;
}
.impianto_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.base_text {
  font-size: 14px;
}
.mobile_link {
  text-decoration: none;
  color: inherit;
}

.pb-0 {
  padding-bottom: 0;
}

.bg-primary {
  background: var(--ion-color-primary, #3880ff) !important;
  padding: 8px;
  color: #ffffff;
}

.card_title {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}

/* New rules for figma layout */
.customer_content {
  padding: 16px;
  padding-bottom: 0;
  margin-bottom: 16px;
}
.customer_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.customer_actions div {
  --background: #747474;
  --box-shadow: none;
  width: 100%;
  background-color: #747474;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 14px 0;
  margin-right: 10px;
}

.customer_actions div:last-child {
  margin-right: 0 !important;
}

.customer_informations {
  width: 100%;
  border-radius: 4px;
  background: #f8f8f8;
}
.customer_informations .item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.customer_informations .item:last-of-type {
  border: none;
}
.customer_informations .item_description {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 18px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.customer_informations .item .label {
  font-size: 12px;
  line-height: 14px;
}
.customer_informations .item .field {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

/* Elenco impianti */
.no_impianti {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  padding: 18px;
  font-weight: 500;
}

.all_impianti_container {
  padding: 16px;
}
.your_impianti {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.your_ticket {
  font-size: 16px;
  font-weight: 600;
}

.all_tickets_container {
  overflow-y: scroll;
  width: 100%;
  margin-top: 16px;
  padding: 10px;
}

.ticket_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #f8f8f8 !important;
}

.ticket_info {
  max-width: 65%;
}
.ticket_customer {
  font-size: 14px;
  font-weight: 500;
}
.ticket_title {
  font-size: 12px;
  color: #919195 !important;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.impianto_detail {
  margin-right: 5px;
}
.custom_chevron {
  margin-left: 6px;
  font-size: 20px;
}

.custom_badge {
  font-size: 10px;
  font-weight: 600 !important;
}

/* .... */
.impianti_associati_container {
  padding: 16px;
  padding-top: 0px;
}
.impianti_associati_container .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
}
.impianti_associati_container .impianto {
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 10px;
  background-color: #f8f8f8;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.impianti_associati_container .impianto .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 8px;
}
.impianti_associati_container .impianto .tipologia {
  font-size: 12px;
  line-height: 14px;
  color: #919195;
}
</style>