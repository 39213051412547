import axios from "axios";
const TOKEN = "Bearer 5bb4cb5e7b93858d18c0e18f85fb1376";

export default {
  async getClienteCommesse(id: string) {
    const data = new FormData();
    data.append("where[projects_customer_id]", id.toString());

    const response = await axios.post("/search/projects", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response.data.data);
    return response.data.data;
  },

  async getCommessaDetail(id: string) {
    const data = new FormData();
    data.append("where[projects_id]", id.toString());

    const response = await axios.post("/search/projects", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response);
    return response.data.data[0];
  },

  async getCommessaLibretto(id: string) {
    const data = new FormData();
    data.append("where[libretto_impianto_impianto]", id.toString());

    const response = await axios.post("/search/libretto_impianto", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response.data.data);
    return response.data.data;
  },

  async getMaterialeCommessa(id: string) {
    const data = new FormData();
    data.append("where[projects_billing_items_project]", id.toString());

    const response = await axios.post("/search/projects_billing_items", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response.data);
    return response.data.data;
  },

  async getInterventiCommessa(commessaId: string) {
    const data = new FormData();
    data.append("orderby", "tickets_reports_date");
    data.append("orderdir", "desc");
    data.append("where[tickets_reports_project_id]", commessaId.toString());

    const response = await axios.post("/search/tickets_reports", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response.data);
    return response.data.data;
  },
};
